/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~swiper/css/bundle";

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap");

:root {
    --ion-font-family: "dm sans" !important;
    --ion-color-primary: #5688c7;
    --ion-color-secondary: #012851;
    --ion-color-success: #05c46b;
}

.flex {
    display: flex;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

ion-header ion-toolbar {
    --background: linear-gradient(160deg, rgba(1, 80, 180, 1) 0%, rgba(105, 149, 204, 1) 88%);
}

.bg-gradient {
    --background: linear-gradient(160deg, rgba(1, 80, 180, 1) 0%, rgba(105, 149, 204, 1) 88%);
}